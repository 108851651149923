<script setup>
import { ref } from 'vue'
import axios from 'axios'

// eslint-disable-next-line
defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})

// eslint-disable-next-line
const emit = defineEmits(['update:modelValue'])

const loading = ref(false)
const invalid = ref(null)
const bearerToken = ref(null)
const token = ref(null)
const username = ref(null)
const logged = ref(false)

function saveToken () {
  // Save data to local storage
  localStorage.setItem('token', token.value)
  localStorage.setItem('username', username.value)
  console.log('Data saved')
  getToken()
}

function getToken () {
  // Retrieve data from local storage
  token.value = localStorage.getItem('token')
  username.value = localStorage.getItem('username')
  console.log('From localStorage => ', token.value, username.value) // 'value'
  logged.value = token.value
  emit('update:modelValue', logged.value)
}

getToken()

function login () {
  loading.value = true
  // Define your headers
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + bearerToken.value // Replace with your actual bearerToken
  }

  axios.get('https://dashboard.bateypelotapty.com/api/user', { headers })
    .then((response) => {
      console.log(response.status)
      if (response.status === 200) {
      // console.log(response.data)
        token.value = response.data.tarjetas[0].token
        username.value = response.data.username
        saveToken()
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          invalid.value.showModal()
        }
        console.log(error)
      }
    })
    .finally(() => {
      loading.value = false
    })
}

function logout () {
  localStorage.removeItem('token')
  localStorage.removeItem('username')
  getToken()
}
</script>
<template>
  <div v-if="logged" class="navbar bg-neutral text-neutral-content justify-between">
    <button class="btn btn-ghost text-xl" @click="logout">Logout</button>
    <h1 class="text-xl">{{ username }}</h1>
  </div>
  <div v-else class="hero min-h-screen bg-base-200">
    <div class="hero-content flex-col lg:flex-row-reverse">
      <div class="text-center lg:text-left">
        <h1 class="text-5xl font-bold">Login</h1>
        <p class="py-6">Introduce el token proporcionado por el administrador</p>
      </div>
      <div class="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <form class="card-body" @submit.prevent="login()">
          <div class="form-control">
            <label class="label">
              <span class="label-text">Token</span>
            </label>
            <input v-model="bearerToken" type="text" placeholder="token" class="input input-bordered" required />
          </div>
          <div class="form-control mt-6">
            <button class="btn btn-primary">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <dialog ref="invalid" class="modal">
    <div class="modal-box">
      <h3 class="font-bold text-lg">Token Invalido!</h3>
      <p class="py-4">El token proporcionado es invalido.</p>
      <div class="modal-action">
        <form method="dialog">
          <!-- if there is a button in form, it will close the modal -->
          <button class="btn">Close</button>
        </form>
      </div>
    </div>
  </dialog>
  <input type="checkbox" v-model="loading" class="modal-toggle" />
  <div class="modal" role="dialog">
    <span class="loading loading-spinner loading-lg text-warning"></span>
  </div>
</template>
