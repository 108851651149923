<script setup>
import { ref } from 'vue'
import ButtonsWebsocket from '@/components/ButtonsWebsocket.vue'
import LoginComponent from '@/components/LoginComponent.vue'

const logged = ref(null)

</script>
<template>
  <LoginComponent v-model="logged"></LoginComponent>
  <ButtonsWebsocket :logged="logged"></ButtonsWebsocket>
</template>
