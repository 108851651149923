<script setup>
import { onMounted, ref, watch } from 'vue'

// eslint-disable-next-line
const props = defineProps(['logged']);

const processing = ref(false)
const uuid = '1621d51d-fd2c-4a29-a961-5dd5bcad42e8'
const token = '7|W81Kf7fzx5lrKTcCtnur411giqkPHNN4Kgscwg2dc43d7d70'

const ping = ref(null)
const socket = ref(null)

class Maquina {
  constructor (uuid, name, i) {
    this.uuid = uuid
    this.name = name
    this.i = i
  }
}

const maquinas = ref([])

function updateData (data) {
  if (typeof data === 'object') {
    console.log(data)
    if ('uuid' in data && 'name' in data && 'i' in data) {
      // Create newMaquina object
      const newMaquina = new Maquina(data.uuid, data.name, data.i)

      // Find the index of the Maquina with the same UUID in the array
      const index = maquinas.value.findIndex(maquina => maquina.uuid === newMaquina.uuid)

      if (index !== -1) {
        // If Maquina with the same UUID exists, update it
        maquinas.value[index] = newMaquina
      } else {
        // If Maquina with the same UUID doesn't exist, append it
        maquinas.value.push(newMaquina)
      }
    }
  }
}

function activateMaquina (uuid, name) {
  const message = {
    name: name,
    uuid: uuid,
    i: true,
    token: props.logged
  }

  send(message)
}

function sendPing () {
  ping.value = setTimeout(() => {
    if (socket.value.readyState === WebSocket.OPEN) {
      socket.value.send('ping')
      sendPing()
    } else {
      openWebSocket()
      sendPing()
    }
  }, 5000)
}

function send (message) {
  if (socket.value.readyState === WebSocket.OPEN) {
    const data = {
      action: 'send',
      to: '1621d51d-fd2c-4a29-a961-5dd5bcad42e8',
      message: message
    }

    socket.value.send(JSON.stringify(data))
  } else {
    setTimeout(() => {
      send(message)
    }, 1000)
  }
}

function openWebSocket () {
  socket.value = new WebSocket('wss://ws.vertexdc.com')

  // socket opened
  socket.value.addEventListener('open', event => {
    processing.value = true

    const login = {
      action: 'login',
      uuid: uuid,
      token: token
    }

    socket.value.send(JSON.stringify(login))

    sendPing()
  })

  // message is received
  socket.value.addEventListener('message', event => {
    const data = JSON.parse(event.data)
    updateData(data.message)

    if (data.message === 'Unauthenticated') {
      // TODO: handle unauthenticated
    } else {
      if (data.message) {
        // TODO: handle message received
      }
    }
  })

  // socket closed
  socket.value.addEventListener('close', event => {
    ping.value = null
    processing.value = false
    // TODO: handle on close
  })

  // error handler
  socket.value.addEventListener('error', event => {
    // send ping for reconnection
    sendPing()
  })
}

// Close socket method
function closeWebSocket () {
  ping.value = null
  socket.value.close()
}

onMounted(() => {
  if (props.logged) {
    openWebSocket()
  }
})

watch(
  () => props.logged,
  logged => {
    if (logged) {
      openWebSocket()
    } else {
      closeWebSocket()
    }
  }
)
</script>
<template v-if="logged">
  <div v-if="socket" class="p-4">
    <span v-if="socket.readyState === 0" class="loading text-error loading-ring loading-lg"></span>
    <span v-if="socket.readyState === 1" class="loading text-success loading-ring loading-lg"></span>
  </div>
  <div class="grid grid-cols-2 gap-4 p-4">
    <button v-for="maquina in maquinas" :key="maquina.uuid" :disabled="maquina.i" class="btn btn-primary p-4" @click="activateMaquina(maquina.uuid, maquina.name)">{{ maquina.name }}</button>
  </div>
</template>
